.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
  position: fixed; /* Make the header fixed to the top */
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure the header stays on top of other content */
}

.header .welcome {
  margin-left: auto; /* Push the rest of the content to the left */
  padding-right: 10px; /* Add some padding to the right */
}

.horizontal-menu {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap between menu items as needed */
  margin: 20px 0; /* Add some margin for spacing */
  background-color: #f0f0f0; /* Background color for the menu */
  padding: 10px; /* Padding inside the menu */
  border-radius: 5px; /* Optional: Rounded corners for the menu */
}

.horizontal-menu a {
  text-decoration: none;
  color: black; /* Adjust the text color as needed */
  padding: 10px 20px; /* Add padding for a better look */
  border: 1px solid transparent; /* Add border for better spacing */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.horizontal-menu a:hover {
  background-color: #ddd; /* Change background color on hover */
  color: black; /* Change text color on hover */
  border: 1px solid #ccc; /* Add border on hover */
}

/* .react-calendar styling */
.calendar-container{
  width: 500px;
  height: 300px;
  padding: 5px;
}
.react-calendar { 
  width: 290px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 5px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em;
 }
 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 5px;
  margin-top: 3px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
 }
 .rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 2px;
  line-height: normal;
  white-space: nowrap;
}
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #683cf833;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
 }

 @media (max-width: 767px) {
  .rbc-toolbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  padding: 1px 3px;
  background-color: #3174ad;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  width: 70%;
  height: 50%;
  text-align: left;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  word-wrap: break-word;
  line-height: 0.7;
  height: 100%;
  font-size: 9px;
  min-height: 0.7em;
}