/* Add some styling to the table headers */
table th {
  border-bottom: 2px solid #000; /* Black border for headers */
  padding: 8px;
  background-color: #f4f4f4; /* Light gray background for header */
  text-align: left;
}

/* Add borders to table cells */
table td {
  border-bottom: 1px solid #ccc; /* Light gray border */
  padding: 8px;
}

/* Style the buttons with purple fill color */
button {
  background-color: #a355da; /* Primary purple fill */
  color: #fff; /* White text to ensure visibility */
  border: 1px solid #a355da; /* Border matches fill color */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Button hover effect with fill color change */
button:hover {
  background-color: #8b46c1; /* Darker purple on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow on hover */
}

/* Active button state */
button:active {
  background-color: #722fa8; /* Even darker purple on active click */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); /* Subtle shadow on active state */
}

/* Disabled button styling */
button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dimmed text color */
  cursor: not-allowed; /* Disabled cursor */
  box-shadow: none; /* No shadow for disabled buttons */
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse; /* Remove gaps between cells */
}